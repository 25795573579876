import React from "react";
import Scroller from "../Scroller/Scroller";
import {
  StyledImageDescription, 
  StyledImageDescriptionRow, 
  StyledImageDescriptionImage,
  StyledImageDescriptionLabel,
  StyledImageDescriptionLabels,
  StyledImageDescriptionLine,
  StyledImageDescriptionLabelBottom,
  StyledImageDescriptionLabelsBottom,
} from "./style";

export default ({
  image,
  theme,
  paddingTop = 0,
  paddingBottom = 0,
  leftLabels = [],
  rightLabels = [],
}) => {
  return (
    <StyledImageDescription paddingTop={paddingTop} paddingBottom={paddingBottom} >
      <StyledImageDescriptionRow>
        <StyledImageDescriptionLabels textAlign='right'>
          {leftLabels.map((item, index) => (
            <div key={index}>
              <StyledImageDescriptionLabel>
                {item.label}
              </StyledImageDescriptionLabel>
              <StyledImageDescriptionLine src='/2020/images/hive-tech-crowd/line-left.png' loading="lazy"/>
            </div>
          ))}
        </StyledImageDescriptionLabels>
        <StyledImageDescriptionImage src={image} loading="lazy"/>
        <StyledImageDescriptionLabels>
          {rightLabels.map((item, index) => (
            <div key={index}>
              <StyledImageDescriptionLabel>
                {item.label}
              </StyledImageDescriptionLabel>
              <StyledImageDescriptionLine src='/2020/images/hive-tech-crowd/line-right.png' loading="lazy"/>
            </div>
          ))}
        </StyledImageDescriptionLabels>
      </StyledImageDescriptionRow>
      <Scroller> 
        <StyledImageDescriptionLabelsBottom>
          {leftLabels.concat(rightLabels).map((item, index) => (
            <div key={index}>
              <StyledImageDescriptionLabelBottom>
                {item.label}
              </StyledImageDescriptionLabelBottom>
            </div>
          ))}
        </StyledImageDescriptionLabelsBottom>
      </Scroller>
    </StyledImageDescription>
  );
};
